import * as React from 'react';
import { graphql } from 'gatsby';

import Policy from 'screens/Policy';

import { GlobalLayout } from 'components/Layout';

type Props = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            path: string;
          };
          html?: string;
        };
      }[];
    };
  };
};

export default function Index({ data }: Props) {
  const currentEdge = data.allMarkdownRemark.edges.find(
    edge => edge.node.frontmatter.path === 'policy',
  );
  const html = currentEdge?.node.html || '';

  return (
    <GlobalLayout>
      <Policy html={html} />
    </GlobalLayout>
  );
}

export const pageQuery = graphql`
  query IndexQueryAndIndexQuery {
    allMarkdownRemark(sort: { fields: [] }) {
      edges {
        node {
          html
          id
          frontmatter {
            path
          }
        }
      }
    }
  }
`;
