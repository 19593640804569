import { styled } from '@linaria/react';

import { COLOR } from '@singledesk/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  h1 {
    margin-bottom: 40px;
  }

  h2 {
    margin: 60px 0 30px;
  }

  h3 {
    margin: 30px 0 20px;
  }

  h4 {
    margin: 20px 0 10px;
  }

  p {
    margin-bottom: 8px;
  }

  a {
    color: var(${COLOR.cfPrimary});

    &:hover {
      color: var(${COLOR.cfPrimaryHover});
    }
  }

  ul {
    padding-left: 30px;

    ul li {
      list-style-type: initial;
    }
  }

  ol {
    padding-left: 30px;
    margin: 16px 0 20px;
  }

  li {
    margin-bottom: 12px;
  }
`;
