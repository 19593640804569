import * as React from 'react';

import { SectionWrapper } from 'components/Layout';

import { Wrapper } from './styled';

type MainProps = {
  html: string;
};

const Main = ({ html }: MainProps) => (
  <SectionWrapper>
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Wrapper>
  </SectionWrapper>
);

export default Main;
